import {
  COMPANY_NAME,
  IMG_LINK_LIST,
  PREFIX_CANONICAL_URL,
  StructuredDataTypes,
  STRUCTURED_DATA_CONTEXT,
} from 'components/constant';
import StructuredDataScript from 'components/structuredData/StructuredDataScript';
import { createBreadcrumbListStructuredData } from 'components/structuredData/structuredDataUtils';
import {
  BreadcrumbListType,
  LocalBusinessType,
} from 'components/structuredData/types';

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useIntl } from 'react-intl';

interface ContactUsStructuredDataProps {
  pageUrl: string;
}

const ContactUsStructuredData = ({ pageUrl }: ContactUsStructuredDataProps) => {
  const { formatMessage } = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allStrapiSocialMediaPrompt {
        nodes {
          SocialNetworkingPlatform {
            Link
          }
        }
      }
      allStrapiLandingPage {
        nodes {
          LocalBusinessStructuredData {
            description
            streetAddress
            addressLocality
            postalCode
            addressCountry
            telephone
            openingHours
            slogan
            image {
              url
            }
          }
        }
      }
      allStrapiContactUs {
        nodes {
          BackgroundImage {
            url
          }
          SEO {
            metaTitle
            metaDescription
          }
        }
      }
    }
  `);

  const { SocialNetworkingPlatform } = data.allStrapiSocialMediaPrompt.nodes[0];

  const {
    BackgroundImage: { url: backgroundImageUrl },
    SEO: { metaTitle, metaDescription },
  } = data.allStrapiContactUs.nodes[0];

  const {
    streetAddress,
    addressLocality,
    postalCode,
    addressCountry,
    telephone,
    openingHours,
  } = data.allStrapiLandingPage.nodes[0].LocalBusinessStructuredData;

  const socialList = SocialNetworkingPlatform.map(
    ({ Link }: { Link: string }) => Link,
  );

  const breadcrumb: BreadcrumbListType = createBreadcrumbListStructuredData(
    pageUrl,
    formatMessage({ id: 'structuredData.contactUs' }),
    formatMessage,
  );

  const publisher: LocalBusinessType = {
    '@type': StructuredDataTypes.LOCAL_BUSINESS,
    '@id': `${pageUrl}#localbusiness`,
    name: COMPANY_NAME,
    logo: IMG_LINK_LIST.logo,
    telephone,
    sameAs: socialList,
    url: pageUrl,
    image: backgroundImageUrl,
    address: {
      '@type': StructuredDataTypes.POSTAL_ADDRESS,
      streetAddress,
      addressLocality,
      postalCode,
      addressCountry,
    },
    openingHours,
  };

  const structuredData = {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@type': StructuredDataTypes.CONTACT_PAGE,
    name: metaTitle,
    description: metaDescription,
    url: pageUrl,
    mainEntityOfPage: {
      '@type': StructuredDataTypes.WEB_PAGE,
      '@id': `${pageUrl}#webpage`,
    },
    breadcrumb,
    publisher,
  };
  return <StructuredDataScript data={structuredData} />;
};

export default ContactUsStructuredData;
