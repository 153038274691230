import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw, { styled } from 'twin.macro';

const ComponentWrapper = styled.div`
  ${tw`flex flex-col text-white lg:mb-[60px] pl-[48px] lg:pl-[80px] pt-[64px] lg:pt-[80px] pr-[30px] h-[400px] lg:h-full relative z-0`}
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      #0b1343 0%,
      rgba(11, 19, 67, 0.962732) 42.19%,
      rgba(11, 19, 67, 0.607128) 99.99%,
      rgba(11, 19, 67, 0) 100%
    );
    opacity: 0.5;
    z-index: -1;
  }
`;

const SubHeading = tw.span`
  text-[16px] lg:text-[18px] lg:font-[500]
`;
const Heading = tw.h1`
  text-[inherit] text-[36px] !font-[600] lg:text-[48px] lg:!font-[500]
`;
const Description = tw.span`
  text-[14px] lg:text-[18px]
`;
const ContactUsHero = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiContactUs {
        nodes {
          BackgroundImage {
            url
          }
          BannerHeader
          BannerSubHeader
          BannerDescription
        }
      }
    }
  `);
  const { BannerHeader, BannerSubHeader, BannerDescription, BackgroundImage } =
    data.allStrapiContactUs.nodes[0];
  return (
    <ComponentWrapper
      style={{
        background: `url(${BackgroundImage.url})
        no-repeat left center / cover`,
      }}
    >
      <SubHeading>{BannerHeader}</SubHeading>
      <Heading>{BannerSubHeader}</Heading>
      <Description>{BannerDescription}</Description>
    </ComponentWrapper>
  );
};

export default ContactUsHero;
