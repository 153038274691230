import * as React from 'react';

import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import ContactUs from 'components/pages/constactUs';
import { graphql } from 'gatsby';
import NewLayout from 'components/NewLayout';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';

const pageUrl = `${PREFIX_CANONICAL_URL}/contact-us`;

const IndexPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <NewLayout headerStyle="light">
        <ContactUs pageUrl={pageUrl} />
      </NewLayout>
    </LanguageProvider>
  );
};
export default IndexPage;

export const Head = ({
  data,
}: {
  data: {
    allStrapiContactUs: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiContactUs.nodes[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiContactUs {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
