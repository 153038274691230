import React from 'react';

interface StructuredDataScriptProps {
  data: object;
}

const StructuredDataScript = ({ data }: StructuredDataScriptProps) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  );
};

export default StructuredDataScript;
