import React, { useEffect, useState } from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import ConnectCard from 'components/connectCard';
import HubspotForm from 'components/HubspotForm';
import './style.css';
import LayoutContainer from 'components/LayoutContainer';
import tw from 'twin.macro';
import { FormTypes, NotificationTypes } from 'components/types';
import { GTM_DATA_LAYER } from 'components/constant';
import useNotification from 'components/notification/useNotification';
import ContactUsHero from './ContactUsHero';
import ContactUsStructuredData from './ContactUsStructuredData';

interface ContactUsProps {
  pageUrl: string;
}

const PageWrapper = tw.div`
  mt-[124px] lg:mt-[142px]
`;
const MapAndFormWrapper = tw.div`
  grid grid-cols-1 lg:grid-cols-2 gap-[80px] lg:pr-[40px]
`;

const ContactUs = ({ pageUrl }: ContactUsProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { showNotification } = useNotification({
    type: NotificationTypes.FORM,
    key: `contact-us`,
  });

  useEffect(() => {
    if (isSubmitted) {
      showNotification();
    }
  }, [isSubmitted]);

  return (
    <LanguageProvider messages={translation}>
      <ContactUsStructuredData pageUrl={pageUrl} />
      <PageWrapper>
        <MapAndFormWrapper>
          <ContactUsHero />
          <div className="px-[30px] xl:px-0 w-full">
            <HubspotForm
              formId="891321a3-7d6c-4a86-bbf3-8dd42e3d2d08"
              onFormSubmitted={() => {
                setIsSubmitted(true);
                if (typeof window !== 'undefined') {
                  window?.dataLayer.push({
                    event: GTM_DATA_LAYER[FormTypes.CONTACT_US],
                  });
                }
              }}
              onFormReady={() => {
                if (isSubmitted) setIsSubmitted(false);
              }}
            />
          </div>
        </MapAndFormWrapper>

        <div className="connect-background bg-accent_grey_1">
          <LayoutContainer>
            <ConnectCard haveBtn={false} location="card" />
          </LayoutContainer>
        </div>
      </PageWrapper>
    </LanguageProvider>
  );
};

export default ContactUs;
