import React, { ReactNode } from 'react';
import { IMG_LINK_LIST } from './constant';

interface SEOProps {
  children?: ReactNode;
  pageTitle: string;
  description?: string;
  isArticle?: boolean;
  ogImg?: string;
  ogTitle?: string;
  ogDescription?: string;
  preventIndexing: boolean | undefined;
  canonicalURL: string | undefined;
}
const SEO = ({
  children,
  pageTitle,
  description,
  isArticle = false,
  ogImg = IMG_LINK_LIST.landingPage.hero,
  ogTitle = pageTitle,
  ogDescription = description,
  preventIndexing,
  canonicalURL,
}: SEOProps) => {
  return (
    <>
      <title>{pageTitle}</title>
      {description && (
        <>
          <meta name="description" content={description} />
          {/* Open Graph Meta Tags */}
          <meta property="og:description" content={ogDescription} />
        </>
      )}
      {preventIndexing && (
        <>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </>
      )}
      {canonicalURL && <link rel="canonical" href={canonicalURL} />}
      {/* Open Graph Meta Tags */}
      <meta property="og:type" content={isArticle ? 'article' : 'website'} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:site_name" content={pageTitle} />
      <meta property="og:image" content={ogImg} />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {children}
    </>
  );
};

export default SEO;
